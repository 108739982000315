import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateInput,
    NumberInput
} from 'react-admin';


export const RfidCardCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="serialNumber"/>
            <NumberInput source="pointsBalance"/>
            <ReferenceInput source="currentHolderId" reference="users"><SelectInput optionText="username"/></ReferenceInput>
            <TextInput source="currentHolderFreeText"/>
            <ReferenceInput source="createdById" reference="users"><SelectInput optionText="username"/></ReferenceInput>
            <ReferenceInput source="accountId" reference="accounts"><SelectInput optionText="name"/></ReferenceInput>
        </SimpleForm>
    </Create>
);