// in src/authProvider.js
export default {
    // called when the user attempts to log in
    login: ({ username, password }) =>  {
        const request = new Request(process.env.REACT_APP_API_URL+'/connect/token', {
            method: 'POST',
            body: `client_id=SecureSpa&client_secret=secret&grant_type=password&username=${username}&password=${password}`,
            headers: new Headers(
                { 'Content-Type': 'application/x-www-form-urlencoded'},
                ),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};