import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateInput,
    NumberInput
} from 'react-admin';


export const RfidCardEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <TextInput source="serialNumber"/>
            <NumberInput source="pointsBalance"/>
            <ReferenceInput source="currentHolderId" reference="users"><SelectInput optionText="username"/></ReferenceInput>
            <TextInput source="currentHolderFreeText"/>
            <DateInput disabled source="createdOn"/>
            <TextInput disabled source="updatedOn"/>
            <ReferenceInput source="createdById" reference="users"><SelectInput optionText="username"/></ReferenceInput>
            <ReferenceInput source="updatedById" reference="users"><SelectInput optionText="username"/></ReferenceInput>
            <ReferenceInput source="accountId" reference="accounts"><SelectInput optionText="name"/></ReferenceInput>
        </SimpleForm>
    </Edit>
);