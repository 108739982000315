// in src/App.js
import * as React from "react";
import { Admin, Resource, ListGuesser, fetchUtils, EditGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-simple-rest';
import {ChargerList} from "./Lists/ChargerList";
import {AccountList} from "./Lists/AccountList";
import {LocationsList} from "./Lists/LocationList";
import {UserList} from "./Lists/UserList";
import {LocationEditForm} from "./EditForms/LocationEditForm";
import {LocationCreateForm} from "./CreateForms/LocationCreateForm";
import authProvider from "./authProvider";
import {ChargerEdit} from "./EditForms/ChargerEditForm";
import {UserEdit} from "./EditForms/UserEditForm";
import {AccountEdit} from "./EditForms/AccountEditForm";
import {ChargerCreateForm} from "./CreateForms/ChargerCreateForm";
import {RfidCardList} from "./Lists/RfidCardList";
import {RfidCardEdit} from "./EditForms/RfidCardEditForm";
import {RfidCardCreate} from "./CreateForms/RfidCardCreateForm";

const httpClient = (url, options = {}) => {
        if (!options.headers) {
                options.headers = new Headers({ Accept: 'application/json' });
        }
        const { access_token } = JSON.parse(localStorage.getItem('auth'));
        options.headers.set('Authorization', `Bearer ${access_token}`);
        return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(process.env.REACT_APP_API_URL+'/api/admin', httpClient);


const App = () =>
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="chargers" list={ChargerList} edit={ChargerEdit} create={ChargerCreateForm}/>
        <Resource name="locations" list={LocationsList} edit={LocationEditForm} create={LocationCreateForm}/>
        <Resource name="users" list={UserList} edit={UserEdit} />
        <Resource name="accounts" list={AccountList} edit={AccountEdit}/>
        <Resource name="rfidcards" list={RfidCardList} edit={RfidCardEdit} create={RfidCardCreate} />
    </Admin>;


export default App;