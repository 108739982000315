import * as React from "react";
import { List, Datagrid, ReferenceField, TextField, DateField, EditButton } from 'react-admin';

export const AccountList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="accountType" />
            <ReferenceField source="ownerId" reference="users"><TextField source="username" /></ReferenceField>
            <DateField source="createdOn" />
            <TextField source="updatedOn" />
            <EditButton/>
        </Datagrid>
    </List>
);