import * as React from "react";
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const LocationCreateForm = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="phoneNumber" />
            <NumberInput source="coordinates.lon" />
            <NumberInput source="coordinates.lat" />
        </SimpleForm>
    </Create>
);