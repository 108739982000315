import * as React from "react";
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

export const AccountEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <DateInput source="createdOn" />
            <TextInput source="updatedOn" />
            <TextInput source="name" />
            <TextInput source="accountType" />
            <ReferenceInput source="ownerId" reference="users"><SelectInput optionText="username" /></ReferenceInput>
        </SimpleForm>
    </Edit>
);