import * as React from "react";
import { List, Datagrid, ReferenceField, TextField, EmailField, EditButton } from 'react-admin';

export const UserList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="username" />
            <EmailField source="email" />
            <ReferenceField source="accountId" reference="accounts"><TextField source="name" /></ReferenceField>
            <EditButton/>
        </Datagrid>
    </List>
);