import * as React from "react";
import { List, Datagrid, NumberField, TextField, EditButton } from 'react-admin';

export const LocationsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="phoneNumber" />
            <NumberField source="coordinates.lon" label="Longitude" />
            <NumberField source="coordinates.lat" label="Latitude" />
            <EditButton/>
        </Datagrid>
    </List>
);