import * as React from "react";
import { List, Datagrid, ReferenceField, TextField, DateField, EditButton } from 'react-admin';

export const ChargerList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="s2WId" label="S2W Id"/>
            <TextField source="s2WHashId" label="S2W Hash Id"/>
            <ReferenceField source="locationId" reference="locations"><TextField source="name" /></ReferenceField>
            <ReferenceField source="accountId" reference="accounts"><TextField source="name" /></ReferenceField>
            <ReferenceField source="createdById" reference="users"><TextField source="username" /></ReferenceField>
            <ReferenceField source="updatedById" reference="users"><TextField source="username" /></ReferenceField>
            <DateField source="createdOn" />
            <DateField source="updatedOn" />
            <EditButton/>
        </Datagrid>
    </List>
);