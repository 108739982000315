import * as React from "react";
import {List, Datagrid, DateField, TextField, ReferenceField, EditButton, NumberField} from 'react-admin';

export const RfidCardList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="serialNumber"/>
            <NumberField source="pointsBalance"/>
            <ReferenceField source="currentHolderId" reference="users"><TextField source="username"/></ReferenceField>
            <TextField source="currentHolderFreeText"/>
            <ReferenceField source="accountId" reference="accounts"><TextField source="name"/></ReferenceField>
            <ReferenceField source="createdById" reference="users"><TextField source="username"/></ReferenceField>
            <ReferenceField source="updatedById" reference="users"><TextField source="username"/></ReferenceField>
            <DateField source="createdOn"/>
            <DateField source="updatedOn"/>
            <EditButton/>
        </Datagrid>
    </List>
);