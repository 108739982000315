import * as React from "react";
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

export const ChargerEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <DateInput disabled source="createdOn" />
            <DateInput disabled source="updatedOn" />
            <ReferenceInput source="createdById" reference="users"><SelectInput optionText="username" /></ReferenceInput>
            <ReferenceInput source="updatedById" reference="users"><SelectInput optionText="username" /></ReferenceInput>
            <TextInput source="s2WId" label="S2W Id"/>
            <TextInput source="s2WHashId" label="S2W Hash Id" />
            <ReferenceInput source="locationId" reference="locations"><SelectInput optionText="name" /></ReferenceInput>
            <ReferenceInput source="accountId" reference="accounts"><SelectInput optionText="name" /></ReferenceInput>
        </SimpleForm>
    </Edit>
);