import * as React from "react";
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const LocationEditForm = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="phoneNumber" />
            <NumberInput source="coordinates.lon" />
            <NumberInput source="coordinates.lat" />
        </SimpleForm>
    </Edit>
);