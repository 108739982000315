import * as React from "react";
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';

export const ChargerCreateForm = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="s2WId" label="S2W Id"/>
            <TextInput source="s2WHashId" label="S2W Hash Id" />
            <ReferenceInput source="locationId" reference="locations"><SelectInput optionText="name" /></ReferenceInput>
            <ReferenceInput source="accountId" reference="accounts"><SelectInput optionText="name" /></ReferenceInput>
        </SimpleForm>
    </Create>
);